import { configure } from './config'
import { TokensFromRefresh } from './model'
import { retrieveTokens, TokensFromStorage } from './storage'
import { initiateAuth } from './cognito-api'
import { setTimeoutWallClock } from './util'

let schedulingRefresh: ReturnType<typeof _scheduleRefresh> | undefined =
	undefined
export async function scheduleRefresh(
	...args: Parameters<typeof _scheduleRefresh>
) {
	if (!schedulingRefresh) {
		schedulingRefresh = _scheduleRefresh(...args).finally(
			() => (schedulingRefresh = undefined),
		)
	}
	return schedulingRefresh
}

type TokensForRefresh = Partial<
	Pick<TokensFromStorage, 'refreshToken' | 'expireAt' | 'username'>
>

let clearScheduledRefresh: ReturnType<typeof setTimeoutWallClock> | undefined =
	undefined
async function _scheduleRefresh({
	abort,
	tokensCb,
	isRefreshingCb,
}: {
	abort?: AbortSignal
	tokensCb?: (res: TokensFromRefresh) => void | Promise<void>
	isRefreshingCb?: (isRefreshing: boolean) => unknown
}) {
	const { debug } = configure()
	clearScheduledRefresh?.()
	const tokens = await retrieveTokens()
	if (abort?.aborted) return
	// Refresh 30 seconds before expiry
	// Add some jitter, to spread scheduled refreshes might they be
	// requested multiple times (e.g. in multiple components)
	const refreshIn = Math.max(
		0,
		(tokens?.expireAt ?? new Date()).valueOf() -
			Date.now() -
			30 * 1000 -
			(Math.random() - 0.5) * 30 * 1000,
	)
	if (refreshIn >= 1000) {
		debug?.(
			`Scheduling refresh of tokens in ${(refreshIn / 1000).toFixed(1)} seconds`,
		)
		clearScheduledRefresh = setTimeoutWallClock(
			() =>
				refreshTokens({ abort, tokensCb, isRefreshingCb, tokens }).catch(err =>
					debug?.('Failed to refresh tokens:', err),
				),
			refreshIn,
		)
		abort?.addEventListener('abort', clearScheduledRefresh)
	} else {
		refreshTokens({ abort, tokensCb, isRefreshingCb, tokens }).catch(err =>
			debug?.('Failed to refresh tokens:', err),
		)
	}
	return clearScheduledRefresh
}

let refreshingTokens: ReturnType<typeof _refreshTokens> | undefined = undefined
export async function refreshTokens(
	...args: Parameters<typeof _refreshTokens>
) {
	if (!refreshingTokens) {
		refreshingTokens = _refreshTokens(...args).finally(
			() => (refreshingTokens = undefined),
		)
	}
	return refreshingTokens
}

const invalidRefreshTokens = new Set<string>()
async function _refreshTokens({
	abort,
	tokensCb,
	isRefreshingCb,
	tokens,
}: {
	abort?: AbortSignal
	tokensCb?: (res: TokensFromRefresh) => void | Promise<void>
	isRefreshingCb?: (isRefreshing: boolean) => unknown
	tokens?: TokensForRefresh
}): Promise<TokensFromRefresh> {
	isRefreshingCb?.(true)
	try {
		const { debug } = configure()
		if (!tokens) {
			tokens = await retrieveTokens()
		}
		const { refreshToken, username } = tokens ?? {}
		if (!refreshToken || !username) {
			throw new Error('Cannot refresh without refresh token and username')
		}
		if (invalidRefreshTokens.has(refreshToken)) {
			throw new Error(
				`Will not attempt refresh using token that failed previously: ${refreshToken}`,
			)
		}
		debug?.('Refreshing tokens using refresh token ...')
		const authResult = await initiateAuth({
			authflow: 'REFRESH_TOKEN_AUTH',
			authParameters: {
				REFRESH_TOKEN: refreshToken,
			},
			abort,
		}).catch(err => {
			invalidRefreshTokens.add(refreshToken)
			throw err
		})
		const tokensFromRefresh: TokensFromRefresh = {
			accessToken: authResult.AuthenticationResult.AccessToken,
			idToken: authResult.AuthenticationResult.IdToken,
			expireAt: new Date(
				Date.now() + authResult.AuthenticationResult.ExpiresIn * 1000,
			),
			username,
		}
		await tokensCb?.(tokensFromRefresh)
		return tokensFromRefresh
	} finally {
		isRefreshingCb?.(false)
	}
}
