import isMobile from '../utils/is-mobile'

import type { Notifications } from './types'

const title = 'Quotes by TechQ'

const email = 'support@mytechq.com'

const messages = {
	app: {
		crash: {
			title: 'Sorry, I guess, something went wrong! You can:',
			options: {
				email: `contact support by this email - ${email}`,
				reset: 'Press here to reset the application',
			},
		},
	},
	loader: {
		fail: 'Hmmmmm, there is something wrong. Please try again later',
	},
	images: {
		failed: 'Something went wrong during image loading! Please try again later',
	},
	404: 'What are you looking for?',
}

const dateFormat = 'MMMM DD, YYYY'

const notifications: Notifications = {
	options: {
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
		autoHideDuration: 6000,
	},
	maxSnack: isMobile ? 3 : 4,
}

const loader = {
	// no more blinking in your app
	delay: 300, // if your asynchronous process is finished during 300 milliseconds you will not see the loader at all
	minimumLoading: 700, // but if it appears, it will stay for at least 700 milliseconds
}

const defaultMetaTags = {
	image: '/cover.png',
	description: 'Starter kit for modern web applications',
}
const giphy404 = 'https://giphy.com/embed/xTiN0L7EW5trfOvEk0'

export {
	loader,
	notifications,
	dateFormat,
	messages,
	email,
	title,
	defaultMetaTags,
	giphy404,
}
