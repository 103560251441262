import { Route, Routes } from 'react-router-dom'

import { usePasswordless } from '../../components/passwordless/react'
import routes from '..'
import React from 'react'

function Pages() {
	const { signInStatus, tokensParsed } = usePasswordless()

	const groups = tokensParsed?.idToken?.['cognito:groups'] || []

	return (
		<>
			<Routes>
				{Object.values(routes).map(
					({ path, component: Component, isPrivate, needsMemberOf }, i) => {
						if (
							!isPrivate ||
							(isPrivate &&
								signInStatus === 'SIGNED_IN' &&
								(!needsMemberOf || needsMemberOf?.length === 0)) ||
							(isPrivate &&
								signInStatus === 'SIGNED_IN' &&
								needsMemberOf?.some(group => groups.includes(group)))
						) {
							return <Route key={i} path={path} element={<Component />} />
						}
					},
				)}
			</Routes>
		</>
	)
}

export default Pages
