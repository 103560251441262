import { useCallback, useMemo, useState } from 'react'

import type { Actions } from './types'

function useSidebar(): [boolean, Actions] {
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const toggle = useCallback(() => {
		setIsOpen((isOpen: boolean) => !isOpen)
	}, [])

	const close = useCallback(() => {
		setIsOpen(false)
	}, [])

	const open = useCallback(() => {
		setIsOpen(true)
	}, [])

	const memoizedActions = useMemo(
		() => ({ toggle, close, open }),
		[toggle, close, open],
	)

	return [isOpen, memoizedActions]
}

export default useSidebar
