import { usePasswordless } from '../passwordless/react'

type RequiresMemberOfProps = {
	accessLevel?:
		| 'SuperAdmin'
		| 'ProductOwner'
		| 'Agency'
		| 'MGA'
		| 'Client'
		| 'BetaTester'
	groups?: string[]
	condition?: 'oneOf' | 'allOf' | 'noneOf'
	children?: React.ReactNode
}

function RequiresMemberOf({
	accessLevel = 'SuperAdmin',
	groups = [],
	condition = 'oneOf',
	children,
}: RequiresMemberOfProps) {
	const { tokensParsed } = usePasswordless()
	const userGroupList = tokensParsed?.idToken?.['cognito:groups'] ?? []

	groups.push('TechQSuperAdmin')
	if (accessLevel !== 'SuperAdmin') {
		groups.push('QuoteAppProductOwner')
	}
	switch (accessLevel) {
		case 'BetaTester':
			groups.push('BetaTester')
			break
		case 'Agency':
			groups.push('AgencyAdmin', 'AgencyManager', 'Agent')
			break
		case 'MGA':
			groups.push('MGAAdmin', 'MGAManager', 'Underwriter')
			break
		default:
			break
	}

	const shouldRenderChildren = () => {
		if (condition === 'allOf') {
			return groups.every(group => userGroupList.includes(group))
		}
		if (condition === 'noneOf') {
			return !groups.some(group => userGroupList.includes(group))
		}
		if (condition === 'oneOf') {
			return groups.some(group => userGroupList.includes(group))
		}
		return false
	}

	return shouldRenderChildren() ? <>{children}</> : null
}

export default RequiresMemberOf
