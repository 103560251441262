import { BrowserRouter } from 'react-router-dom'

import { Fragment, useState } from 'react'

import { usePasswordless } from './components/passwordless/react/hooks'
import { withErrorHandler } from './error-handling'
import AppErrorBoundaryFallback from './error-handling/fallbacks/App'
import { twMerge } from 'tailwind-merge'

import { Helmet } from 'react-helmet-async'

import Pages from './routes/Pages'
import Header from './sections/Header'
import Notifications from './sections/Notifications'
import SW from './sections/SW'
import Sidebar from './sections/Sidebar'
import useSidebar from './store/sidebar'
// import { Banner } from 'flowbite-react'
// // import { FaBookOpen } from "react-icons/fa";
// import FeedbackFormModal from './components/TechQ/FeedbackFormModal'
import React from 'react'

function App() {
	const { signInStatus } = usePasswordless()
	const [isSidebarOpen] = useSidebar()

	const [showStepUpAuth, setShowStepUpAuth] = useState(false)
	if (showStepUpAuth && signInStatus !== 'SIGNED_IN') setShowStepUpAuth(false)

	return (
		<Fragment>
			{/*
        Helmet allows us to set baseline metadata. If Helmet is used on child components,
        it will merge the metadata with the parent component with the child component's
        metadata taking precedence.
      */}
			<Helmet>
				<title>Quotes by TechQ</title>
				<meta name='description' content='Quotes by TechQ' />

				<link
					href='https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap'
					rel='stylesheet'
					type='text/css'
				/>
			</Helmet>

			<div className={twMerge('bg-gray-50 dark:bg-gray-800')}>
				<Notifications />
				<SW />
				<div className='mx-auto flex h-screen flex-col items-center justify-center px-6 dark:text-white'>
					<BrowserRouter>
						<Header />
						<div className='flex items-start'>
							<Sidebar />
							<div
								id='main-content'
								className={twMerge(
									'relative size-full h-[calc(100vh-8rem)] overflow-y-auto',
									isSidebarOpen ? 'lg:ml-64' : 'lg:ml-16',
								)}
							>
								<Pages />
							</div>
						</div>
					</BrowserRouter>
				</div>
			</div>
		</Fragment>
	)
}

const AppWithErrorHandler = withErrorHandler(App, AppErrorBoundaryFallback)

export default AppWithErrorHandler
