import { Sidebar, TextInput, Tooltip } from 'flowbite-react'
import type { ComponentProps, FC, HTMLAttributeAnchorTarget } from 'react'
import { useEffect, useState } from 'react'
import {
	HiAdjustments,
	HiChartPie,
	HiClipboardList,
	HiCog,
	HiDocumentReport,
	HiOutlinePaperClip,
	HiPaperAirplane,
	HiSearch,
	HiSupport,
} from 'react-icons/hi'
import { HiRocketLaunch } from 'react-icons/hi2'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { usePasswordless } from '../../components/passwordless/react'
import useSidebar from '../../store/sidebar'
import RequiresMemberOf from '../../components/TechQ/RequiresMemberOf'

interface SidebarItem {
	href?: string
	target?: HTMLAttributeAnchorTarget
	icon?: FC<ComponentProps<'svg'>>
	label: string
	items?: SidebarItem[]
	badge?: string
	enabled?: boolean
	isBetaTester?: boolean
}

interface SidebarItemProps extends SidebarItem {
	pathname: string
}

export default function DashboardSidebar() {
	const { signInStatus } = usePasswordless()

	if (signInStatus !== 'SIGNED_IN') return <></>

	return (
		<>
			<div className='lg:hidden'>
				<MobileSidebar />
			</div>
			<div className='hidden lg:block'>
				<DesktopSidebar />
			</div>
		</>
	)
}

// function isUserInGroup(groupNames: string[]): boolean {
//   fetchAuthSession().then((session) => {
//     const groups =
//       (session.tokens?.idToken?.payload["cognito:groups"] as string[]) ?? [];
//     // Test if user is in any of the groups with logging of which group the user is in
//     for (const groupName of groupNames) {
//       if (groups.includes(groupName)) {
//         console.log("User is in group", groupName);
//         return true;
//       }
//     }
//   });
//   console.log("User is not in any of the groups provided:", groupNames);
//   return false;
// }

function DesktopSidebar() {
	const [isSidebarOpen] = useSidebar()
	const { pathname } = useLocation()
	// const [isPreview, setIsPreview] = useState(!isSidebarOpen);

	// useEffect(() => {
	//   if (!isSidebarOpen) setIsPreview(false);
	// }, [isSidebarOpen]);

	// const preview = {
	//   enable() {
	//     if (!isSidebarOpen) return;

	//     setIsPreview(true);
	//     sidebarActions.open();
	//   },
	//   disable() {
	//     if (!isPreview) return;

	//     sidebarActions.close();
	//   },
	// };

	return (
		<Sidebar
			// onMouseEnter={preview.enable}
			// onMouseLeave={preview.disable}
			aria-label='Sidebar'
			collapsed={!isSidebarOpen}
			className={twMerge(
				'fixed inset-y-0 left-0 z-20 flex h-full shrink-0 flex-col border-r border-gray-200 pt-16 duration-75 dark:border-gray-700 lg:flex',
				!isSidebarOpen && 'hidden w-16',
			)}
			id='sidebar'
		>
			<div className='flex h-full flex-col justify-between'>
				<div className='py-2'>
					<Sidebar.Items>
						<Sidebar.ItemGroup className='mt-0 border-t-0 pb-1 pt-0'>
							{pages.map(item => (
								<SidebarItem key={item.label} {...item} pathname={pathname} />
							))}
							<RequiresMemberOf accessLevel='Agency'>
								{agencyPages.map(item => (
									<SidebarItem key={item.label} {...item} pathname={pathname} />
								))}
							</RequiresMemberOf>
							<RequiresMemberOf accessLevel='MGA'>
								{mgaPages.map(item => (
									<SidebarItem key={item.label} {...item} pathname={pathname} />
								))}
							</RequiresMemberOf>
							<RequiresMemberOf accessLevel='BetaTester'>
								{betaPages.map(item => (
									<SidebarItem key={item.label} {...item} pathname={pathname} />
								))}
							</RequiresMemberOf>
							<RequiresMemberOf accessLevel='ProductOwner'>
								{adminPages.map(item => (
									<SidebarItem key={item.label} {...item} pathname={pathname} />
								))}
							</RequiresMemberOf>
						</Sidebar.ItemGroup>
						<Sidebar.ItemGroup className='mt-2 pt-2'>
							{externalPages.map(item => (
								<SidebarItem key={item.label} {...item} pathname={pathname} />
							))}
						</Sidebar.ItemGroup>
					</Sidebar.Items>
				</div>
				<BottomMenu isCollapsed={!isSidebarOpen} />
			</div>
		</Sidebar>
	)
}

function MobileSidebar() {
	const [isSidebarOpen] = useSidebar()
	const { pathname } = useLocation()

	// const [isPreview, setIsPreview] = useState(!isSidebarOpen);
	const [, setIsPreview] = useState(!isSidebarOpen)

	useEffect(() => {
		if (!isSidebarOpen) setIsPreview(false)
	}, [isSidebarOpen])

	if (!isSidebarOpen) return null

	// useEffect(() => {
	//   if (!isSidebarOpen) setIsPreview(false);
	// }, [isSidebarOpen]);

	// const preview = {
	//   enable() {
	//     if (!isSidebarOpen) return;

	//     setIsPreview(true);
	//     sidebarActions.open();
	//   },
	//   disable() {
	//     if (!isPreview) return;

	//     sidebarActions.close();
	//   },
	// };

	return (
		<>
			<Sidebar
				aria-label='Sidebar'
				className={twMerge(
					'fixed inset-y-0 left-0 z-20 hidden h-full shrink-0 flex-col border-r border-gray-200 pt-16 dark:border-gray-700 lg:flex',
					isSidebarOpen && 'flex',
				)}
				id='sidebar'
			>
				<div className='flex h-full flex-col justify-between'>
					<div className='py-2'>
						<RequiresMemberOf accessLevel='BetaTester'>
							<form className='pb-3'>
								<TextInput
									icon={HiSearch}
									type='search'
									placeholder='Search'
									required
									size={32}
								/>
							</form>
						</RequiresMemberOf>
						<Sidebar.Items>
							<Sidebar.ItemGroup className='mt-0 border-t-0 pb-1 pt-0'>
								{pages.map(item => (
									<SidebarItem key={item.label} {...item} pathname={pathname} />
								))}
								<RequiresMemberOf accessLevel='Agency'>
									{agencyPages.map(item => (
										<SidebarItem
											key={item.label}
											{...item}
											pathname={pathname}
										/>
									))}
								</RequiresMemberOf>
								<RequiresMemberOf accessLevel='MGA'>
									{mgaPages.map(item => (
										<SidebarItem
											key={item.label}
											{...item}
											pathname={pathname}
										/>
									))}
								</RequiresMemberOf>
								<RequiresMemberOf accessLevel='BetaTester'>
									{betaPages.map(item => (
										<SidebarItem
											key={item.label}
											{...item}
											pathname={pathname}
										/>
									))}
								</RequiresMemberOf>
								<RequiresMemberOf accessLevel='ProductOwner'>
									{adminPages.map(item => (
										<SidebarItem
											key={item.label}
											{...item}
											pathname={pathname}
										/>
									))}
								</RequiresMemberOf>
							</Sidebar.ItemGroup>
							<Sidebar.ItemGroup className='mt-2 pt-2'>
								{externalPages.map(item => (
									<SidebarItem key={item.label} {...item} pathname={pathname} />
								))}
							</Sidebar.ItemGroup>
						</Sidebar.Items>
					</div>
					<BottomMenu isCollapsed={false} />
				</div>
			</Sidebar>
			<div
				onClick={close}
				aria-hidden='true'
				className='fixed inset-0 z-10 size-full bg-gray-900/50 pt-16 dark:bg-gray-900/90'
			/>
		</>
	)
}

function SidebarItem({
	href,
	target,
	icon,
	label,
	items,
	badge,
	pathname,
	isBetaTester = false,
	enabled = true,
}: SidebarItemProps) {
	// console.log("label", label);
	// console.log("href", href);
	// console.log("pathname", pathname);
	if (items) {
		const isOpen = items.some(item => pathname.startsWith(item.href ?? ''))

		return (
			<Sidebar.Collapse
				icon={icon}
				label={label}
				open={isOpen}
				// theme={{ list: "space-y-2 py-2  [&>li>div]:w-full" }}
				className={twMerge(enabled || isBetaTester ? 'flex' : 'hidden')}
			>
				{/* Filter items by item.enabled !== false */}
				{items.map(
					item =>
						item.enabled !== false && (
							<Sidebar.Item
								key={item.label}
								href={item.href}
								target={item.target}
								className={twMerge(
									enabled || isBetaTester ? 'flex' : 'hidden',
									pathname === href && 'bg-gray-100 dark:bg-gray-700',
								)}
							>
								{item.label}
							</Sidebar.Item>
						),
				)}
			</Sidebar.Collapse>
		)
	}

	return (
		<Sidebar.Item
			href={href}
			target={target}
			icon={icon}
			label={badge}
			className={twMerge(
				enabled || isBetaTester ? 'flex' : 'hidden',
				pathname === href && 'bg-gray-100 dark:bg-gray-700',
			)}
		>
			{label}
		</Sidebar.Item>
	)
}

function BottomMenu({ isCollapsed }: { isCollapsed: boolean }) {
	return (
		<div
			className={twMerge(
				'flex items-center justify-center gap-4',
				isCollapsed && 'flex-col',
			)}
		>
			<RequiresMemberOf accessLevel='SuperAdmin'>
				<Tooltip content='Debug'>
					<Link
						to='/debug'
						className='inline-flex cursor-pointer justify-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white'
					>
						<span className='sr-only'>Debug</span>
						<HiAdjustments className='size-6' />
					</Link>
				</Tooltip>
			</RequiresMemberOf>
			<Tooltip content='Settings'>
				<Link
					to='/settings'
					className='inline-flex cursor-pointer justify-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white'
				>
					<span className='sr-only'>Settings page</span>
					<HiCog className='size-6' />
				</Link>
			</Tooltip>
		</div>
	)
}

const pages: SidebarItem[] = [
	{ href: '/', icon: HiChartPie, label: 'Dashboard' },
	{
		icon: HiOutlinePaperClip,
		href: '/quotes',
		label: 'Manage quotes',
		enabled: true,
	},
	{
		icon: HiPaperAirplane,
		href: '/quotes/request',
		label: 'Request a quote',
		enabled: true,
	},
]

const agencyPages: SidebarItem[] = [
	// {
	//   icon: HiShieldCheck,
	//   label: "Agency",
	//   items: [
	//     // Agent view
	//     { href: "/manage/clients", label: "Manage clients" },
	//     // Agency Admin / Agency Manager view
	//     { href: "/manage/agents", label: "Manage agents" },
	//     // MGA Admin view to manage the MGA
	//     { href: "/manage/agency", label: "Manage agency" },
	//   ],
	//   enabled: true,
	// },
]

const mgaPages: SidebarItem[] = [
	// {
	//   icon: HiNewspaper,
	//   label: "MGA",
	//   items: [
	//     // Underwriter view
	//     { href: "/manage/clients", label: "Manage clients" },
	//     // MGA Admin / MGA Manager view
	//     { href: "/manage/underwriters", label: "Manage underwriters" },
	//     // MGA Admin view to manage the MGA
	//     { href: "/manage/mga", label: "Manage MGA" },
	//   ],
	//   enabled: true,
	// },
]

const betaPages: SidebarItem[] = [
	// {
	//   href: "/tasks",
	//   icon: HiViewGrid,
	//   label: "Tasks",
	//   enabled: true,
	// },
	// {
	//   href: "/mailing/inbox",
	//   icon: HiInboxIn,
	//   label: "Inbox",
	//   badge: "3",
	//   enabled: true,
	// },
]

const adminPages: SidebarItem[] = [
	{
		label: 'Billing',
		icon: HiClipboardList,
		href: '/billing',
	},
	{
		label: 'Admin',
		icon: HiCog,
		href: '/admin',
	},
]

const externalPages: SidebarItem[] = [
	{ href: '/pricing', icon: HiDocumentReport, label: 'Pricing' },
	// {
	//   href: "https://techq.app/",
	//   target: "_blank",
	//   icon: HiClipboardList,
	//   label: "Docs",
	// },
	{
		href: 'https://techq.app/',
		target: '_blank',
		icon: HiSupport,
		label: 'Support',
	},
	{
		href: 'https://techq.app/',
		target: '_blank',
		icon: HiRocketLaunch,
		label: 'TechQ',
	},
]
