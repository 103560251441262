/* eslint-disable react-refresh/only-export-components */
// import { useCallback, useEffect, useRef } from 'react';

// import Alert from 'flowbite-react';
// import Button from 'flowbite-react';

// import type { SnackbarKey } from 'notistack';

// import useNotifications from '../../store/notifications';

function SW() {
	// const [, notificationsActions] = useNotifications();
	// const notificationKey = useRef<SnackbarKey | null>(null);
	// const {
	//   offlineReady: [offlineReady, setOfflineReady],
	//   needRefresh: [needRefresh, setNeedRefresh],
	//   updateServiceWorker,
	// } = useRegisterSW();

	// const close = useCallback(() => {
	//   setOfflineReady(false);
	//   setNeedRefresh(false);

	//   if (notificationKey.current) {
	//     notificationsActions.close(notificationKey.current);
	//   }
	// }, [setOfflineReady, setNeedRefresh, notificationsActions]);

	// useEffect(() => {
	//   if (offlineReady) {
	//     notificationsActions.push({
	//       options: {
	//         autoHideDuration: 4500,
	//         content: "App is ready to work offline.",
	//       },
	//     });
	//   } else if (needRefresh) {
	//     notificationKey.current = notificationsActions.push({
	//       message: 'New content is available, click on reload button to update.',
	//       options: {
	//         variant: 'warning',
	//         persist: true,
	//         action: (
	//           <>
	//             <Button onClick={() => updateServiceWorker(true)}>Reload</Button>
	//             <Button onClick={close}>Close</Button>
	//           </>
	//         ),
	//       },
	//     });
	//   }
	// }, [close, needRefresh, offlineReady, notificationsActions, updateServiceWorker]);

	return <></>
}

export default SW
