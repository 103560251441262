// import AddTaskIcon from '@mui/icons-material/AddTask';
// import BugReportIcon from '@mui/icons-material/BugReport';
// import GitHubIcon from '@mui/icons-material/GitHub';
// import HomeIcon from '@mui/icons-material/Home';
// import TerrainIcon from '@mui/icons-material/Terrain';

// import { HiHome } from 'react-icons/hi';

import { HiChartBar } from 'react-icons/hi'
import asyncComponentLoader from '../utils/loader'

import { Routes } from './types'

const routes: Routes = [
	{
		title: 'Dashboard',
		path: '/',
		component: asyncComponentLoader(() => import('../pages/Dashboard')),
		isPrivate: false,
		icon: HiChartBar,
		isMenu: true,
	},
	{
		title: 'Login',
		path: '/login',
		component: asyncComponentLoader(() => import('../pages/Login')),
		isPrivate: false,
		isMenu: false,
	},
	{
		title: 'Register',
		path: '/register',
		component: asyncComponentLoader(() => import('../pages/Register')),
		isPrivate: false,
		isMenu: false,
	},
	{
		title: 'Profile',
		path: '/profile',
		component: asyncComponentLoader(() => import('../pages/Profile')),
		isPrivate: true,
		isMenu: false,
	},
	{
		title: 'Settings',
		path: '/settings',
		component: asyncComponentLoader(() => import('../pages/Profile')),
		isPrivate: true,
		isMenu: false,
	},
	{
		title: 'Quotes',
		path: '/quotes',
		component: asyncComponentLoader(() => import('../pages/Quotes')),
		isPrivate: true,
		isMenu: true,
	},
	{
		title: 'Request a Quote',
		path: '/quotes/request',
		component: asyncComponentLoader(
			() => import('../pages/Quotes/RequestQuote'),
		),
		isPrivate: true,
		isMenu: true,
		needsMemberOf: [
			'Agent',
			'AgencyManager',
			'AgencyAdmin',
			'QuoteAppProductOwner',
			'TechQSuperAdmin',
		],
	},
	{
		title: 'View Quote Request',
		path: '/quotes/view/:quoteRequestId',
		component: asyncComponentLoader(
			() => import('../pages/Quotes/ViewQuoteRequest'),
		),
		isPrivate: true,
		isMenu: true,
		needsMemberOf: [
			'Agent',
			'AgencyManager',
			'AgencyAdmin',
			'Underwriter',
			'MGAManager',
			'MGAAdmin',
			'QuoteAppProductOwner',
			'TechQSuperAdmin',
		],
	},
	{
		title: 'Admin',
		path: '/admin',
		component: asyncComponentLoader(() => import('../pages/Admin')),
		isPrivate: true,
		isMenu: true,
		needsMemberOf: [
			'AgencyManager',
			'AgencyAdmin',
			'MGAManager',
			'MGAAdmin',
			'QuoteAppProductOwner',
			'TechQSuperAdmin',
		],
	},
	{
		title: 'Debug',
		path: '/debug',
		component: asyncComponentLoader(() => import('../pages/Debug')),
		isPrivate: true,
		isMenu: false,
	},
	{
		title: 'Profile',
		path: '/profile',
		component: asyncComponentLoader(() => import('../pages/Debug')),
		isPrivate: true,
		isMenu: false,
	},
	{
		title: '404',
		path: '*',
		component: asyncComponentLoader(() => import('../pages/NotFound')),
		isPrivate: false,
		isMenu: false,
	},
]

export default routes
