import { useCallback, useMemo, useReducer } from 'react'
import type { SnackbarKey } from 'notistack'
import { notifications as notificationsDefaults } from '../../config'
import { Actions, Notification } from './types'

type State = Notification[]
type Action =
	| { type: 'PUSH'; payload: Partial<Notification> }
	| { type: 'CLOSE'; payload: { key: SnackbarKey; dismissAll: boolean } }
	| { type: 'REMOVE'; payload: { key: SnackbarKey } }

const notificationsReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'PUSH':
			const id = Math.random().toString()
			return [
				...state,
				{
					...action.payload,
					message: action.payload.message,
					dismissed: false,
					options: {
						...notificationsDefaults.options,
						...action.payload.options,
						key: id,
					},
				},
			]
		case 'CLOSE':
			return state.map(notification =>
				(
					action.payload.dismissAll ||
					notification.options.key === action.payload.key
				) ?
					{ ...notification, dismissed: true }
				:	{ ...notification },
			)
		case 'REMOVE':
			return state.filter(
				notification => notification.options.key !== action.payload.key,
			)
		default:
			return state
	}
}

function useNotifications(): [Notification[], Actions] {
	const [notifications, dispatch] = useReducer(notificationsReducer, [])

	const push = useCallback(
		(notification: Partial<Notification>) => {
			dispatch({ type: 'PUSH', payload: notification })
			return notification.options?.key || ''
		},
		[dispatch],
	)

	const close = useCallback(
		(key: SnackbarKey, dismissAll = !key) => {
			dispatch({ type: 'CLOSE', payload: { key, dismissAll } })
		},
		[dispatch],
	)

	const remove = useCallback(
		(key: SnackbarKey) => {
			dispatch({ type: 'REMOVE', payload: { key } })
		},
		[dispatch],
	)

	const actions = useMemo(
		() => ({ push, close, remove }),
		[push, close, remove],
	)

	return [notifications, actions]
}

export default useNotifications
