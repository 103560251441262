import welcome from './utils/welcome'

import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { RecoilRoot } from 'recoil'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { I18nProvider } from '@cloudscape-design/components/i18n'
import enMessages from '@cloudscape-design/components/i18n/messages/all.en'

import {
	PasswordlessContextProvider,
	// Passwordless as PasswordlessComponent,
	Fido2Toast,
} from './components/passwordless/react'
import { Flowbite, ThemeModeScript } from 'flowbite-react'
import ConfigureAmplifyClientSide from './components/Amplify/ConfigureAmplify'
import { customTheme } from './theme'

// import { Amplify } from "aws-amplify";
// import outputs from "../amplify_outputs.json";

import App from './App'

import './components/passwordless/passwordless.css'
import '@aws-amplify/ui-react/styles.css'
import '@cloudscape-design/global-styles'
import './globals.css'
import { SnackbarProvider } from 'notistack'

// Amplify.configure(outputs);

const stripePromise = loadStripe(
	'pk_test_51P4Z8YIJklKdyKRfR80GDhrEYARrZiVRsD7rF8Kin0Df2VUTg9byjit9HsRxw6ZuQbtO7qTgd4GiLX4WGGkKQSLD00UVSZn3qp',
)

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
	<>
		<StrictMode>
			<ThemeModeScript />
			{/* <RecoilRoot> */}
			<HelmetProvider>
				<PasswordlessContextProvider enableLocalUserCache={true}>
					<>
						<Flowbite theme={{ theme: customTheme }}>
							<ConfigureAmplifyClientSide />
							<SnackbarProvider />
							{/* <I18nProvider locale='en' messages={[enMessages]}> */}
							<Elements stripe={stripePromise}>
								<App />
							</Elements>
							{/* </I18nProvider> */}
						</Flowbite>
						<Fido2Toast />
					</>
				</PasswordlessContextProvider>
			</HelmetProvider>
			{/* </RecoilRoot> */}
		</StrictMode>
	</>,
)

// welcome message for users in the console
welcome()

// ts(1208)
export {}
